import React from 'react'
import { Box, styled, Typography } from "@mui/material";
import Skoobhome from '../assets/Skoobhome.svg'
import Top from '../assets/Top.svg'
import { useSpring, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer';

const Home = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
      });
      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(15%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 400 },
        immediate: !inView,
        reset: true,
      });
    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(10),
        backgroundImage: `url(${Top})`,
        height:'100vh',
        backgroundSize:'cover' ,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        }
    }));
    return (
        <Box
        id="Home"
        ref={ref}
        >
            <CustomBox component='header'>
                <animated.div style={{
                        ...springProps,
                    }}
                    >
                <Box
                    sx={{ marginLeft:{xs:0,sm:0,md:'5vw',lg:'5vw',xl:'5vw'}}}
                    >
                        <Typography
                            variant='h2'
                            component='h1'
                            sx={{
                                width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'50vw',
                                    lg:'50vw',
                                    xl:'50vw'
                                },
                                marginTop:{
                                    xs:'60vh',
                                    sm:'60vh',
                                    md:'25vh',
                                    lg:'25vh',
                                    xl:'25vh'
                                },
                                color: '#fff',
                                fontFamily: 'Open Sans',
                                fontWeight: 700,
                                fontSize: {
                                    xs:35,
                                    sm:45,
                                    md:55,
                                    lg:75,
                                    xl:95,
                                },
                            }}
                        >
                            Revolutionize your book-reading experience 
                        </Typography>
                        <Typography
                            variant='p'
                            component='p'
                            sx={{
                                py: 3,
                                lineHeight: 1.8,
                                color: '#fff',
                                fontSize: {
                                    xs:14,
                                    sm:16,
                                    md:18,
                                    lg:20,
                                    xl:22,
                                },
                                fontFamily: 'Open Sans',
                                fontWeight: 500,
                                width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'45vw',
                                    lg:'45vw',
                                    xl:'45vw'
                                },
                            }}
                        >
                        Gen AI-powered platform to absorb insightful knowledge from multiple books served in form of bite-sized content. Ready to Skoob it? Let's roll !
                        </Typography>
                        {/* <Box sx={{ width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'45vw',
                                    lg:'45vw',
                                    xl:'45vw'
                                },justifyContent:{
                                    xs:'center',
                                    sm:'center',
                                    md:'flex-start',
                                    lg:'flex-start',
                                    xl:'flex-start'
                                },display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{marginRight:1}}>
                                <img
                                    src={appstore}
                                    alt="headerImg"
                                    style={{
                                    width:'100%'
                                }}
                                />
                            </Box>
                            <Box>
                                <img
                                    src={playstore}
                                    alt="headerImg"
                                    style={{
                                        width:'100%'
                                    }}
                                />
                            </Box>
                        </Box> */}
                    </Box>
                </animated.div>
                <Box sx={{
                     position: 'absolute',
                     top:{xs:100,sm:100,md:250,lg:200,xl:200},
                     right:{md:20,lg:20,xl:20},
                     width:{xs:'90vw',sm:'60vw',md:'45vw',lg:'45vw',xl:'45vw'}
                }}
                >
                    <img
                        src={Skoobhome}
                        alt="headerImg"
                        style={{
                            width:'100%',
                        }}
                    />
                </Box>
            </CustomBox>
        </Box >
    )
}

export default Home