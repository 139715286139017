import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';


const UnsubscribeEmails = () => {
  const { uid } = useParams();
  const [ unsubscribed, setUnsubscribed ] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage ] = useState(null);

  const handleUnsubscribe = async () => {
    try {
        const backend_host = localStorage.getItem('BACKEND_URL');
        // const backend_host = "http://127.0.0.1:8000"
        await axios.get(`${backend_host}/email/unsubscribe/${uid}`,)
            .then(response => {
                if (response.status === 200) {
                    const { message } = response.data
                    setUnsubscribed(true);
                    setMessage(message);
                }
            })
        .catch(error => {
            console.error(error);
            setError("Something went wrong, Please try again");
        })
    } catch (err) {
      console.error(err);
      setError("Something went wrong, Please try again");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      left="20%"
      width="60%"
      height="10%"
      sx={{
        marginTop: {
            xs:'30vh',
            sm:'30vh',
            md:'15vh',
            lg:'15vh',
            xl:'15vh'
        }}}
      fontFamily="Open Sans, sans-serif"
    >
      {unsubscribed ? (
        <Typography variant="h5">
            {message && <Typography variant="body1">{message}</Typography>}
        </Typography>
      ) : (
        <div>
          <Typography variant="h5">
            Are you sure you want to unsubscribe from our emails?
          </Typography>
          <Button variant="text" color="primary" onClick={handleUnsubscribe}
          sx={{marginTop: {
                xs:'1vh',
                sm:'1vh',
                md:'1vh',
                lg:'1vh',
                xl:'1vh'
            }}}>
            Unsubscribe
          </Button>
          {error && <Typography variant="body1">{error}</Typography>}
        </div>
      )}
    </Box>
  );
};

export default UnsubscribeEmails;
