import React from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography,
    styled,
    ListItemButton,
  } from '@mui/material';
import AppImage from '../assets/AppImage.svg';
const WhatIsSkoob = () => {
  return (
        <Box
            sx={{
                backgroundColor: '#1E0E49',
                paddingBottom:'43px',
                color: '#fff',
                paddingTop: {
                    xs:'5vh',
                    sm:'5vh',
                    md:'5vh',
                    lg:'15vh',
                    xl:'15vh'
                },
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'center',
                alignItems:'center',
                width:'100vw'
            }}
        >
        <Box >
            <Box sx={{width:'100vw'}}> 
                <Typography sx={{textAlign:'center',fontSize: 18, fontFamily: 'Open Sans',
                                fontWeight: '700', }}>What is Skoob?</Typography>
                <Typography sx={{ textAlign:'center', marginTop: '14px', marginLeft:'5vw',marginRight:'5vw',fontSize: 12, fontFamily: 'Open Sans',
                                fontWeight: '300',}}>Skoobs are smart collections of insights from various non-fiction books on the same topic, saving you time and effort in reading.</Typography>
            </Box>
            <Box sx={{marginTop:'24px',justifyContent:'center',alignItems:'center',width:'100vw'}}
                >
                <img
                    src={AppImage}
                    alt="headerImg"
                    style={{width:'60vw',marginLeft:'20vw'}}
                />
            </Box>
            <Box sx={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',width:'100vw'}}>
                    <Box sx={{
                        width:{
                            xs:'90vw',
                            sm:'90vw',
                            md:'90vw',
                            lg:'90vw',
                            xl:'90vw'
                        }, marginTop: '26px', display: 'flex',flexDirection: 'row', height:50,justifyContent:'center', alignItems:'center', border: '1px solid #4c339e', borderRadius: '10px'}}>
                            <Typography
                                variant="body1"
                                component="body1" 
                                sx={{ fontFamily: 'Open Sans',fontSize:'16px',color:'#B191FE',
                                fontWeight: '700', }}>
                                Gen AI       
                                <Typography  variant="body1"
                                component="body1" 
                                 sx={{ fontFamily: 'Open Sans',fontSize:'16px',color:'#fff', fontWeight: '700',marginLeft:'5px' }}>
                                    curated content                           
                                </Typography>                  
                            </Typography>
                    </Box>
                    <Box sx={{
                        width:{
                            xs:'90vw',
                            sm:'90vw',
                            md:'90vw',
                            lg:'90vw',
                            xl:'90vw'
                        }, marginTop: '20px', display: 'flex',flexDirection: 'row', height:50,justifyContent:'center', alignItems:'center', border: '1px solid #4c339e', borderRadius: '10px'}}>
                            <Typography
                                variant="body1"
                                component="body1" 
                                sx={{ fontFamily: 'Open Sans',fontSize:'16px',color:'#B191FE',
                                fontWeight: '700', }}>
                                Merging content into     
                                <Typography  variant="body1"
                                component="body1" 
                                 sx={{ fontFamily: 'Open Sans',fontSize:'16px',color:'#fff', fontWeight: '700',marginLeft:'5px' }}>
                                    "skoobs"                           
                                </Typography>                  
                            </Typography>
                    </Box>
                    <Box sx={{
                        width:{
                            xs:'90vw',
                            sm:'90vw',
                            md:'90vw',
                            lg:'90vw',
                            xl:'90vw'
                        }, marginTop: '20px', display: 'flex',flexDirection: 'row', height:50,justifyContent:'center', alignItems:'center', border: '1px solid #4c339e', borderRadius: '10px'}}>
                            <Typography
                                variant="body1"
                                component="body1" 
                                sx={{ fontFamily: 'Open Sans',fontSize:'16px',color:'#B191FE',
                                fontWeight: '700', }}>
                                Stories  inspired from      
                                <Typography  variant="body1"
                                component="body1" 
                                 sx={{ fontFamily: 'Open Sans',fontSize:'16px',color:'#fff', fontWeight: '700',marginLeft:'5px' }}>
                                non-fiction books                           
                                </Typography>                  
                            </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default WhatIsSkoob;