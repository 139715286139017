import React, { useState } from 'react'
import {
  styled,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  Typography,
  ListItemButton
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { Link as ScrollLink} from 'react-scroll';
import Close from '../assets/close.png';
const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

//rotas
const itemList = [
  {
    text: 'Home',
    to: 'Home', // replace with the ID of the target component
  },
  {
    text: 'About',
    to: 'Features', // replace with the ID of the target component
  },
  {
    text: 'Features',
    to: 'AboutMindverse', // replace with the ID of the target component
  },
  {
    text: 'Why',
    to: 'Services', // replace with the ID of the target component
  },
  {
    text: 'How',
    to: 'HowItWorks', // replace with the ID of the target component
  },
];


const DrawerItem = () => {

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            backgroundColor: '#1D1956;'
          },
        }}
        // variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <img src={Close} onClick={handleDrawerClose} alt='close'></img>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList.map((item) => {
            const { text, to } = item;
            return (
              <ListItem key={text}>
                <ScrollLink
                  to={to}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-200}
                  activeClass="active"
                  initialClassName="active"
                >
                  <ListItemButton
                    sx={{
                      color:'#fff',
                        fontSize: 18,
                      '&:hover': {
                        color:'#B9ACEA',
                        backgroundColor: '#463D7C',
                        borderRadius:1,
                      },

                    }}
                  >
                    <Typography sx={{fontFamily: 'Open Sans',fontWeight: '400', fontSize: 15 ,width:160}}>{text}</Typography>
                  </ListItemButton>
                </ScrollLink>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  )
}

export default DrawerItem;