import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import WhatIsSkoob from '../components/WhatIsSkoob';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Trivia from '../components/Trivia';
import TrackPlayer from '../components/TrackPlayer'
import ReadPage from '../components/ReadPage'
import SkoobDetail from '../components/SkoobDetail'
import {
  Box,
} from '@mui/material';
const DeepLinkLanding = () => {
  const { id ,skoob_id,story_id,} = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const textAfterFirstSlash = parts[1];
  return (
    <Box sx={{ backgroundColor:'#1E0E49' }}>
      <Navbar />
      {textAfterFirstSlash === 'trivia'&&<Trivia id={id}/>}
      {textAfterFirstSlash === 'trackplayer'&&<TrackPlayer skoob_id={skoob_id} story_id={story_id} />}
      {textAfterFirstSlash === 'readpage'&&<ReadPage  skoob_id={skoob_id} story_id={story_id} />}
      {textAfterFirstSlash === 'skoobdetails'&&<SkoobDetail  id={id}/>}
      <WhatIsSkoob/>
      <Footer/>
    </Box>
  )
}

export default DeepLinkLanding