import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import Background from "../assets/Background.svg";
import Skoobdetaildownload from "../assets/Skoobdetaildownload.svg";
import contract from "../assets/contract.svg"
import newsstand from "../assets/newsstand.svg"
import headphones from "../assets/headphones.svg"
import axios from 'axios';

const SkoobDetail = (props) => {
  const [uri, setUri] = useState('');
  useEffect(() => {
    setUri(localStorage.getItem('url'));
  }, [])
  const [skoob, setSkoob] = useState({});
  useEffect(() => {
    const FetchCollectionDetails = async () => {
      const BACKEND_URL = localStorage.getItem('BACKEND_URL');
      const user_id = localStorage.getItem('unique_key');
      await axios.get(`${BACKEND_URL}/collection`, {
        params: { collection_id: props.id }, headers: {
          'Authorization': user_id
        }
      },)
        .then(response => {
          console.log(response.data[0]);
          setSkoob(response.data[0]);
        })
        .catch(error => {
        });
    }
    FetchCollectionDetails();
  }, [])

  return (
    <>
      {skoob && <Box sx={{
        backgroundColor: "#1E0E49",
        marginTop: {
          xs: "12vh",
          sm: "10vh",
          md: "10vh",
          lg: "10vh",
          xl: "10vh"
        }
      }}>
        <Box sx={{ position: 'relative', marginTop: -3 }}>
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: {
              xs: "2.5vh",
              sm: "5vh",
              md: "5vh",
              lg: "10vh",
              xl: "10vh"
            }
          }}>
            <img src={skoob.img_url} alt="headerImg" style={{ width: "70vw", borderRadius: '12px' }} />
          </Box>
        </Box>
        <Box sx={{ marginTop: "34px", position: 'relative' }}>
          <img src={Background} alt="headerImg" style={{ width: "100vw" }} />
          <Typography
            variant="p"
            component="p"
            sx={{
              position: 'absolute',
              top: 0,
              marginTop: '1vh',
              color: "#fff",
              fontSize: {
                xs: 16,
                sm: 20,
                md: 24,
                lg: 28,
                xl: 32,
              },
              fontFamily: "Open Sans",
              fontWeight: '700',
              width: "100vw",
              textAlign: 'center',
            }}
          >
            {skoob.title}
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{
              position: 'absolute',
              top: 0,
              marginTop: '4vh',
              color: "#fff",
              fontSize: {
                xs: 12,
                sm: 16,
                md: 20,
                lg: 24,
                xl: 28,
              },
              fontFamily: "Open Sans",
              fontWeight: '300',
              width: "100vw",
              textAlign: 'center',
            }}
          >
            {skoob.tagline}
          </Typography>
          <Box sx={{ position: 'absolute', top: 0, display: 'flex', flexDirection: 'row', marginTop: '7vh', justifyContent: 'center', width: '80vw', marginLeft: '10vw' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{
                height: {
                  xs: 14,
                  sm: 18,
                  md: 22,
                  lg: 26,
                  xl: 30,
                }
              }}>
                <img src={headphones} alt="headerImg" style={{ marginRight: '4.5px' }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 18,
                    md: 22,
                    lg: 26,
                    xl: 30,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {Math.floor(skoob.total_time / 60)} mins
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '5vw', display: 'flex', flexDirection: 'row' }} >
              <Box sx={{
                height: {
                  xs: 14,
                  sm: 18,
                  md: 22,
                  lg: 26,
                  xl: 30,
                }
              }}>
                <img src={newsstand} alt="headerImg" style={{ marginRight: '4.5px' }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 18,
                    md: 22,
                    lg: 26,
                    xl: 30,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {skoob.total_books} Books
              </Typography>
            </Box>
            <Box sx={{ marginLeft: '5vw', display: 'flex', flexDirection: 'row' }} >
              <Box sx={{
                height: {
                  xs: 14,
                  sm: 18,
                  md: 22,
                  lg: 26,
                  xl: 30,
                }
              }}>
                <img src={contract} alt="headerImg" style={{ marginRight: '4.5px' }} />
              </Box>
              <Typography
                variant="block1"
                component="block1"
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: 14,
                    sm: 18,
                    md: 22,
                    lg: 26,
                    xl: 30,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {skoob.total_stories} Stories
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            width: '100vw', height: '0.5px', backgroundColor: '#FFFFFF', position: 'absolute', top: 0, marginTop: {
              xs: '10vh',
              sm: '13vh',
              md: '18vh',
              lg: '28vh',
              xl: '33vh',
            }
          }}></Box>
          <Typography
            variant="p"
            component="p"
            sx={{
              position: 'absolute',
              top: 0,
              marginTop: {
                xs: '12vh',
                sm: '15vh',
                md: '20vh',
                lg: '30vh',
                xl: '35vh',
              },
              color: "#fff",
              fontSize: {
                xs: 18,
                sm: 24,
                md: 30,
                lg: 36,
                xl: 42,
              },
              fontFamily: "Open Sans",
              fontWeight: '300',
              width: "80vw",
              textAlign: 'center',
              marginLeft: '10vw',
              marginRight: '10vw'
            }}
          >
            Download Skoob and Immerse Yourself in '{skoob.title}'
          </Typography>
          <Box sx={{
            position: 'absolute', bottom: {
              xs: '12vh',
              sm: '15vh',
              md: '25vh',
              lg: '30vh',
              xl: '35vh',
            }, width: '100vw',
          }}>
            <img src={Skoobdetaildownload} alt="headerImg" style={{ width: "50vw", marginLeft: '25vw' }} />
          </Box>
          <a href={uri} style={{ textDecoration: 'none', }} target="_blank">
            <Box sx={{
              position: 'absolute', display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', bottom: {
                xs: '30px',
                sm: '30px',
                md: '30px',
                lg: '30px',
                xl: '30px',
              }, borderRadius: '6px', backgroundColor: '#FFFFFF', width: '90vw', marginLeft: '5vw', height: '45px', marginRight: '5vw',
            }}>
              <text
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: '600', color: '#342067', fontSize: {
                    xs: 14,
                    sm: 16,
                    md: 18,
                    lg: 20,
                    xl: 22,
                  },
                }}
              >Download Now
              </text>
            </Box>
          </a>
        </Box>
      </Box>}
    </>
  );
};

export default SkoobDetail;
