import React from 'react'
import About from '../components/About';
import Features from '../components/Features';
import Home from '../components/Home';
import WhySkoob from '../components/WhySkoob';
import HowItWorks from '../components/HowItWorks';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Main = () => {
  return (
    <div
      style={{ backgroundColor: '#100047',width:'100vw'}}
    >
      <Navbar />    
      <Home />
      <About />
      <Features />
      <WhySkoob />
      <HowItWorks />
      <Footer/>
    </div>
  )
}

export default Main