import React, { useEffect ,useState} from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import TriviaBack from "../assets/TriviaBack.svg";
import TriviaImage from "../assets/TriviaImage.svg";

import axios from 'axios';

const Trivia = (props) => {
  const [uri,setUri]=useState('');
  useEffect(()=>{
    setUri(localStorage.getItem('url'));
  },[])
  const [trivia,setTrivia]=useState({});
  const [stories,setStories]=useState({});
  const [collection,setCollection]=useState({});
  const FetchTrivia=async()=>{
    const BACKEND_URL=localStorage.getItem('BACKEND_URL');
    const user_id=localStorage.getItem('unique_key');
    await axios.get(`${BACKEND_URL}/story/trivia`,{
          params:{
              id:props.id
          },
          headers: {
              'Authorization': user_id,
          }
        })
        .then(response => {
          setTrivia(response.data[0].Trivia);
          setStories(response.data[0].stories)
          setCollection(response.data[0].collection)
        })
        .catch(error => {
        })
  }
  useEffect(()=>{
    FetchTrivia()
  },[])

  return (
    <>
   {trivia.length>0&&<Box sx={{ backgroundColor: "#1E0E49",
      marginTop: {
      xs: "12vh",
      sm: "10vh",
      md: "10vh",
      lg: "10vh",
      xl: "10vh" }}}>
      <Box sx={{position:'relative',
                    marginTop:{
                          xs: -3,
                          sm: 0,
                          md: 0,
                          lg: 0,
                          xl: 0,
                  },
              }}>
        <Box>
          <Box sx={{height:{
                          xs: '50vh',
                          sm: '50vh',
                          md: '60vh',
                          lg: '60vh',
                          xl: '60vh', }}}>
            <img src={trivia[0]?.img_url} alt="headerImg" style={{ width: "100vw",height:'100%'}} />
          </Box>
          <Box sx={{backgroundColor:'#040404',position:'absolute',top:0,height:'100%',width:'100vw',opacity:0.6}}></Box>
          <Box sx={{position:'absolute',top:0,display:'flex',alignItems:'center',height:'100%',}}>
            <Box sx={{display:'flex',flexDirection:'row'}}>
                <Box sx={{width:'6px',marginLeft:'10vw',backgroundColor:'#D4D4D4'}}></Box>
                <Box sx={{width:'80vw',marginLeft:'5vw'}}>
                  <Typography
                      variant="p"
                      component="p"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: 24,
                          sm: 34,
                          md: 44,
                          lg: 54,
                          xl: 64,
                        },
                        fontFamily: "Open Sans",
                        fontWeight: '700',
                        }}
                        >
                     {trivia[0]?.text}
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: 14,
                          sm: 18,
                          md: 22,
                          lg: 26,
                          xl: 30,
                        },
                        fontFamily: "Open Sans",
                        fontWeight: '600',
                        width: "70vw",
                        marginTop:'11px'
                        }}
                        >
                      {stories[0]?.title}
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: 10,
                          sm: 14,
                          md: 18,
                          lg: 22,
                          xl: 26,
                        },
                        fontFamily: "Open Sans",
                        fontWeight: '400',
                        width: "70vw",
                        }}
                        >
                      {`from "${stories[0]?.book.name.length > 20 ? `${stories[0]?.book.name.substring(0, 20)}...` : stories[0]?.book.name}" by ${stories[0]?.book.author.length > 20 ? `${stories[0]?.book.author.substring(0, 20)}...` : stories[0]?.book.author}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
        </Box>
          <Box sx={{ marginTop: "34px",position:'relative' }}>
              <img src={TriviaBack} alt="headerImg" style={{ width: "100vw" }} />
              <Typography
                variant="p"
                component="p" 
                sx={{
                  position:'absolute',
                  top:0,
                  marginTop:{
                  xs: '5vh',
                  sm: '10vh',
                  md: '15vh',
                  lg: '20vh',
                  xl: '25vh',
                },
                  color: "#fff",
                  fontSize: {
                    xs: 18,
                    sm: 24,
                    md: 30,
                    lg: 36,
                    xl: 42,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '300',
                  width: "70vw",
                  textAlign:'center',
                  marginLeft:'15vw',
                  marginRight:'15vw'
                }}
                >
                Quick Insights, Anytime, Anywhere: Download Now for Quick Access
              </Typography>
          <Box sx={{position:'absolute',bottom:{
              xs: '12vh',
              sm: '15vh',
              md: '25vh',
              lg: '30vh',
              xl: '35vh',
            },width:'100vw',}}>
            <img src={TriviaImage} alt="headerImg" style={{ width: "40vw",marginLeft:'30vw'}} />
          </Box>
          <a href={uri} style={{textDecoration:'none',}} target="_blank">
            <Box sx={{position:'absolute', display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',bottom:{
                xs: '30px',
                sm: '30px',
                md: '30px',
                lg: '30px',
                xl: '30px',
              },borderRadius:'6px',backgroundColor:'#FFFFFF',width:'90vw',marginLeft:'5vw',height:'45px',marginRight:'5vw',}}>
                <text
                    style={{ fontFamily: 'Open Sans',
                    fontWeight:'600',color:'#342067',fontSize: {
                      xs:14,
                      sm:16,
                      md:18,
                      lg:20,
                      xl:22,
                    },
                  }}
                  >Download Now
                </text>
            </Box>
            </a>
        </Box>
      </Box>}
    </>
  );
};

export default Trivia;
