import React, { useEffect ,useState} from "react";
import axios from 'axios';
import {
  Box,
  Typography,
} from "@mui/material";
import DualBackground from "../assets/DualBackground.svg";
import ReadPageImage from "../assets/ReadPageImage.svg";

const TrackPlayer = (props) => {
  const [uri,setUri]=useState('');
  useEffect(()=>{
    setUri(localStorage.getItem('url'));
  },[])
  const [story,setStory]=useState({});
  const FetchStoriesDetails = async ()=>{
    const BACKEND_URL= localStorage.getItem('BACKEND_URL');
    const user_id= localStorage.getItem('unique_key');
    await axios.get(`${BACKEND_URL}/story/all/${props.skoob_id}`, { headers: {
      'Authorization': user_id
    } },)
        .then(response => {
          response.data.stories.forEach(myFunction)
          function myFunction(value, index, array){
            if(value.id === props.story_id){
              setStory(value)
              console.log(value);
            }
          }
        })
      .catch(error => {
      })
  }
  useEffect(()=> {
    FetchStoriesDetails()
  },[])
  return (
    <>
    {story&&<Box sx={{ backgroundColor: "#1E0E49",
      marginTop: {
        xs: "12vh",
        sm: "10vh",
        md: "10vh",
        lg: "10vh",
        xl: "10vh"
      }
      }}>
        <Box sx={{position:'relative',marginTop:-3}}>
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:{
            xs: "2.5vh",
            sm: "5vh",
            md: "5vh",
            lg: "10vh",
            xl: "10vh"
            }
          }}>
            <img src={story.book?.img_url} alt="headerImg" style={{ width: "40vw",height:'25vh',borderRadius:'8px'}} />
          </Box>
        </Box>
        <Box sx={{ marginTop: "34px",position:'relative' }}>
          <img src={DualBackground} alt="headerImg" style={{ width: "100vw" }} />
              <Typography
                variant="p"
                component="p" 
                sx={{
                  position:'absolute',
                  top:0,
                  marginTop:'2vh',
                  color: "#fff",
                  fontSize: {
                    xs: 16,
                    sm: 20,
                    md: 24,
                    lg: 28,
                    xl: 32,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '700',
                  width: "90vw",
                  textAlign:'center',
                  marginLeft:'5vw'
                }}
                >
                {story.title}
              </Typography>
          <Box sx={{width:'100vw',height:'0.5px',backgroundColor:'#FFFFFF',position:'absolute',top:0, marginTop:{
                  xs: '10vh',
                  sm: '13vh',
                  md: '18vh',
                  lg: '28vh',
                  xl: '33vh',
                }}}></Box>
          <Typography
                variant="p"
                component="p" 
                sx={{
                  position:'absolute',
                  top:0,
                  marginTop:{
                  xs: '12vh',
                  sm: '15vh',
                  md: '20vh',
                  lg: '30vh',
                  xl: '35vh',
                },
                  color: "#fff",
                  fontSize: {
                    xs: 18,
                    sm: 24,
                    md: 30,
                    lg: 36,
                    xl: 42,
                  },
                  fontFamily: "Open Sans",
                  fontWeight: '300',
                  width: "80vw",
                  textAlign:'center',
                  marginLeft:'10vw',
                  marginRight:'10vw'
                }}
                >
                Start Listening to story on Skoob App!
              </Typography>
          <Box sx={{position:'absolute',bottom:{
              xs: '12vh',
              sm: '15vh',
              md: '25vh',
              lg: '30vh',
              xl: '35vh',
            },width:'100vw',}}>
            <img src={ReadPageImage} alt="headerImg" style={{ width: "50vw",marginLeft:'25vw'}} />
          </Box>
          <a href={uri} style={{textDecoration:'none',}} target="_blank">
            <Box sx={{position:'absolute', display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',bottom:{
                xs: '30px',
                sm: '30px',
                md: '30px',
                lg: '30px',
                xl: '30px',
              },borderRadius:'6px',backgroundColor:'#FFFFFF',width:'90vw',marginLeft:'5vw',height:'45px',marginRight:'5vw',}}>
                <text
                    style={{ fontFamily: 'Open Sans',
                    fontWeight:'600',color:'#342067',fontSize: {
                      xs:14,
                      sm:16,
                      md:18,
                      lg:20,
                      xl:22,
                    },
                  }}
                  >Download Now
                </text>
            </Box>
          </a>
        </Box>
      </Box>}
    </>
  );
};

export default TrackPlayer;
