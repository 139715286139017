import React from 'react'
import {
    Box,
    Typography,
} from '@mui/material'
import VastCollection from '../assets/VastCollection.svg'
import ReadorListen from '../assets/ReadorListen.svg'
import Suggestion from '../assets/Suggestion.svg'
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web'


const Features = () => {
    const [ref, inView] = useInView({
      triggerOnce: true,
    });
    const springProps = useSpring({
      from: { opacity: 0, transform: 'translateY(50%)' },
      to: async (next) => {
        await next({ opacity: 1, transform: 'translateY(0%)' });
      },
      config: { duration: 500 },
      immediate: !inView,
      reset: true,
    });
    return (
        <animated.div id="AboutMindverse" style={{
            ...springProps,
        }} >
        <Box ref={ref} sx={{ color: '#fff' ,marginTop:{xs:5,sm:5,md:5,lg:5,xl:5},}}>
            <Box sx={{justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
                <Typography sx={{ textAlign: 'center', fontFamily: 'Open Sans',
                                fontWeight: 700, fontSize: {xs:34,sm:34,md:54,lg:54,xl:54},}}>Features</Typography>
                <Typography sx={{width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'60vw',
                                    lg:'60vw',
                                    xl:'60vw'
                                },textAlign: 'center', fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: {xs:16,sm:16,md:20,lg:20,xl:20}, }}>The Ultimate Collection of Knowledge</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: {xs:'center',sm:'center',md:'space-around',lg:'space-around',xl:'space-around'},alignItems:{xs:'center',sm:'center',md:'space-around',lg:'space-around',xl:'space-around'}, flexWrap: 'wrap',marginTop:2}} >
                <Box sx={{width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'30vw',
                                    lg:'30vw',
                                    xl:'30vw'
                                },marginTop:5,marginLeft:{xs:'5%',sm:'5%',md:'1vw'},}}>
                    <Box sx={{ display: 'flex'}}>
                        <Box sx={{ justifyContent:'center',alignItems:'center',border: '1px solid green', borderRadius: '50%', width: 60, height: 60, textAlign: 'center' }}>
                            <Typography sx={{ textAlign:'center',fontFamily: 'Josefin Sans', fontSize: 32, marginTop: 1 }}>1</Typography>
                        </Box>
                        <Typography sx={{fontFamily: 'Open Sans',
                                fontWeight: 700,fontSize: 24, marginLeft: 2, marginTop: 2 }}>Vast collection</Typography>
                    </Box>
                    <Typography sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 18, marginTop: 2.6}}>Step into a universe of information, where each skoob is a gateway to endless wisdom</Typography>
                    <Box sx={{marginTop: 10 ,alignItems:'center',justifyContent:'center'}}>
                            <img
                                src={VastCollection}
                                alt="walletimg"
                                width={'90%'}
                                style={{borderRadius:10}}
                            />
                    </Box>
                </Box>
                <Box sx={{marginTop:5,marginLeft:{xs:'5%',sm:'5%',md:0},width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'30vw',
                                    lg:'30vw',
                                    xl:'30vw'
                                }}}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ border: '1px solid green', borderRadius: '50%', width: 60, height: 60, textAlign: 'center' }}>
                            <Typography sx={{ fontFamily: 'Josefin Sans', fontSize: 32, marginTop: 1 }}>2</Typography>
                        </Box>
                        <Typography sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700,fontSize: 24,  marginLeft: 2,marginTop: 2 }}>Read OR Listen</Typography>
                    </Box>
                    <Typography sx={{fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 18,marginTop: 2.6 }}>Engage in reading or listening to skoobs that encapsulate the essence of several books</Typography>
                    <Box sx={{marginTop: 10 }}>
                            <img
                                src={ReadorListen}
                                alt="walletimg"
                                width={'90%'}
                                style={{borderRadius:10}}
                            />
                    </Box>
                </Box>
                <Box sx={{marginTop:5,marginLeft:{xs:'5%',sm:'5%',md:0},width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'30vw',
                                    lg:'30vw',
                                    xl:'30vw'
                                }}}>
                    <Box sx={{ display: 'flex'}}>
                        <Box sx={{ border: '1px solid green', borderRadius: '50%', width: 60, height: 60, textAlign: 'center' }}>
                            <Typography sx={{ fontFamily: 'Josefin Sans', fontSize: 32, marginTop: 1 }}>3</Typography>
                        </Box>
                        <Typography sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700, fontSize: 24,  marginLeft: 2, marginTop: 2 }}>Personalised Suggestions</Typography>
                    </Box>
                    <Typography sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400,fontSize: 18, marginTop: 2.6}}>Specially crafted skoobs just for you,turning your experience into a knowledge adventure!</Typography>
                    <Box sx={{ marginTop: 10 }}>
                            <img
                                src={Suggestion}
                                alt="walletimg"
                                width={'90%'}
                                style={{borderRadius:10}}

                            />
                    </Box>
                </Box>
            </Box>
            <Box sx={{marginTop:15,justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
                <Typography sx={{textAlign:'center',fontSize: {xs:30,sm:45,md:54,lg:54,xl:54},fontFamily: 'Open Sans',
                                fontWeight: 700,width:'90vw' }}>Why Skoob?</Typography>
                {/* <Typography sx={{ textAlign:'center', marginTop: 2, fontSize: 16, fontFamily: 'Josefin Sans', width:'90vw'}}>Your Curated Knowledge Library</Typography> */}
            </Box>
        </Box>
    </animated.div>
    )
}

export default Features;