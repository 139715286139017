import React from 'react'
import {
    Box,
    Typography,
} from '@mui/material'
import Mockupskoob from '../assets/Mockupskoob.svg'
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web'

const About = () => {
      const [ref, inView] = useInView({
        triggerOnce: true,
      });
      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(50%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 500 },
        immediate: !inView,
        reset: true,
      });
    return (
        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
        {/* <Box sx={{
            position:'absolute',
            top:{
                xs:750,
                sm:900,
                md:850,
                lg:900,
                xl:1100,
            }
        }}>
            <Cards />
        </Box> */}
        <Box
         id="Features"
            sx={{
                backgroundColor: '#100047',
                color: '#fff',
                marginTop:{xs:15,sm:5,md:5,lg:5,xl:5},
                display: 'flex',
                flexDirection: 'row',
                justifyContent:'center',
            }}
        >
        <Box >
            <Box>
                <Typography sx={{textAlign:{xs:'center',sm:'center',md:'left'},fontSize: 54, fontFamily: 'Open Sans',
                                fontWeight: 700, }}>What is Skoob?</Typography>
                <Typography sx={{ textAlign:{xs:'center',sm:'center',md:'left'}, marginTop: 2, fontSize: 16, fontFamily: 'Open Sans',
                                fontWeight: 500,width: {
                                    xs:'90vw',
                                    sm:'90vw',
                                    md:'45vw',
                                    lg:'45vw',
                                    xl:'45vw'
                                },marginLeft: {
                                    xs:'5vw',
                                    sm:'5vw',
                                    md:'0vw',
                                    lg:'0vw',
                                    xl:'0vw'
                                }, }}>Skoobs are smart collections of insights from various non-fiction books on the same topic, saving you time and effort in reading.</Typography>
            </Box>
            <Box sx={{justifyContent:{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'},alignItems:{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'},display:'flex',flexDirection:'column'}}>
                <Box sx={{
                    width:{
                    xs:'80vw',
                    sm:'80vw',
                    md:'40vw',
                    lg:'40vw',
                    xl:'40vw'
                    }, alignItems:'center',marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                        {/* <img
                            src={multi}
                            alt="headerImg"
                            height={100}
                            width={90}
                        /> */}
                        <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700, }}>
                            Gen AI curated content
                             </Typography>
                            <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 16 }}>
                            Our Gen AI technology gathers valuable insights from a range of non-fiction books.
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                    width:{
                    xs:'80vw',
                    sm:'80vw',
                    md:'40vw',
                    lg:'40vw',
                    xl:'40vw'
                    }, alignItems:'center',marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                        {/* <img
                            src={multi}
                            alt="headerImg"
                            height={100}
                            width={90}
                        /> */}
                        <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700,}}>
                            Merging content into 'skoobs'                             </Typography>
                            <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 16 }}>
                            The Gen AI curated content is blended into collections of stories i.e. “skoobs”                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        width:{
                        xs:'80vw',
                        sm:'80vw',
                        md:'40vw',
                        lg:'40vw',
                        xl:'40vw'}, justifyContent:'center',alignItems:'center', borderColor: '#fff', borderWidth: 2, marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                        {/* <img
                            src={app}
                            alt="headerImg"
                            height={100}
                            width={90}
                        /> */}
                        <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700, }}>
                            Multiple books                            </Typography>
                            <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 16,fontStyle:'normal' }}>
                            Discover insights from various books in our stories about the same topic, saving you from the need to read the entire book.
                              </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
                <animated.div style={{
                    ...springProps
                }} >
                <Box ref={ref}  sx={{
                       display:{xs:'none',sm:'none',md:'flex'},
                        marginLeft:'5vw',
                        marginTop:{md:'20vh',lg:'20vh',xl:'0vh'},
                        width:'40vw',
                }
                //     theme => ({
                // [theme.breakpoints.down('md')]: {
                //     flex: '1',
                //     display: 'none',
                //     },
                //     [theme.breakpoints.up('md')]: {
                //         flex: '1',
                //     },
                //     marginLeft:'5vw',
                //     marginTop:'20vh',
                //     width:'40vw',
                // })
            }
                >
                <img
                    src={Mockupskoob}
                    alt="headerImg"
                    style={{width:'100%'}}
                />
            </Box>
            </animated.div>
        </Box>
        </Box>
    )
}

export default About;



