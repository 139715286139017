import React from 'react'
import { Box, Button, Container, styled, Typography } from "@mui/material";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web'
import secure from '../assets/secure.png'
import TimeSaver from '../assets/TimeSaver.svg'
import EasyInsights from '../assets/EasyInsights.svg'
import DivPers from '../assets/DivPers.svg'
import Authentic from '../assets/Authentic.svg'
import feature from '../assets/feature.svg'

const WhySkoob = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, 
      });
      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(50%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 500 },
        immediate: !inView, 
        reset: true,
      });

  return (
    <Box
    id="Services"
    sx={{
        backgroundColor: '#100047',
        color: '#fff',
        marginTop: 5,
        display: 'flex',
        flexDirection: 'row',
        marginLeft:{md:'5vw',lg:'5vw',xl:'5vw'},
        justifyContent:{xs:'center',sm:'center',md:'flex-start',lg:'flex-start',xl:'flex-start'}
    }}
>
    <Box >
        <Box sx={{justifyContent:{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'},alignItems:{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'},display:'flex',flexDirection:'column'}}>
            <Box sx={{ width:{
                    xs:'80vw',
                    sm:'80vw',
                    md:'45vw',
                    lg:'40vw',
                    xl:'40vw'
                },marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                    <img
                        src={TimeSaver}
                        alt="headerImg"
                        height={100}
                        width={90}
                        style={{borderRadius:10,marginTop:8}}
                    />
                    <Box sx={{ marginTop: 2, marginLeft: 2 ,display:'flex',flexDirection:'column'}}>
                    <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700, }}>    
                        Time-Saver
                        </Typography>
                        <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontWeight: '400', fontSize: 16 }}>
                        Save time by not having to read the whole book for valuable information
                        </Typography>
                    </Box>
                </Box>
            <Box sx={{ width:{
                xs:'80vw',
                sm:'80vw',
                md:'45vw',
                lg:'40vw',
                xl:'40vw'
            },marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                    <img
                        src={EasyInsights}
                        alt="headerImg"
                        height={100}
                        width={90}
                        style={{borderRadius:10,marginTop:8}}
                    />
                        <Box sx={{ marginTop: 2, marginLeft: 2 ,display:'flex',flexDirection:'column'}}>
                        <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700, }}>Easy Insights</Typography>
                        <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 16 }}>
                            No more lengthy reads, our bite-sized content makes reading easy and efficient</Typography>
                        </Box>
                    </Box>
            <Box sx={{ width:{
                    xs:'80vw',
                    sm:'80vw',
                    md:'45vw',
                    lg:'40vw',
                    xl:'40vw'
                },borderColor: '#fff', borderWidth: 2, marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                 <img
                        src={DivPers}
                        alt="headerImg"
                        height={100}
                        width={90}
                        style={{borderRadius:10,marginTop:8}}
                    />
                <Box sx={{ marginTop: 2, marginLeft: 2 ,display:'flex',flexDirection:'column'}}>
                <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700,}}>
                    Diverse Perspectives
                    </Typography>
                    <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400,fontSize: 16 }}>
                    Get Knowledge on one topic from multiple books
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ width:{
                    xs:'80vw',
                    sm:'80vw',
                    md:'45vw',
                    lg:'40vw',
                    xl:'40vw'
                },borderColor: '#fff', borderWidth: 2, marginTop: 5, display: 'flex', flexDirection: 'row', backgroundColor: 'transparent', border: '1px solid #4c339e', borderRadius: 5, padding: 3 }}>
                 <img
                        src={Authentic}
                        alt="headerImg"
                        height={100}
                        width={90}
                        style={{borderRadius:10,marginTop:8}}
                    />
                <Box sx={{ marginTop: 2, marginLeft: 2 ,display:'flex',flexDirection:'column'}}>
                <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700, }}>
                        Authentic Information
                    </Typography>
                    <Typography color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, fontSize: 16 }}>
                        Because our information comes straight from real-world books, you can trust that it's the real deal
                    </Typography>
                </Box>
            </Box>
        </Box>
        </Box>
        <animated.div style={{
                    ...springProps
                }} >
            <Box ref={ref} sx={{
                display:{xs:'none',sm:'none',md:'flex'},
                marginLeft:'3vw',    
                marginTop:{md:'20vh',lg:'10vh',xl:'0vh'},
                width:'40vw',
            }}
            >
            <img
                src={feature}
                alt="headerImg"
                style={{width:'40vw'}}
            />
        </Box>
    </animated.div>
</Box>
  )
}

export default WhySkoob