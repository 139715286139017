import React from 'react'
import { Box,Container,Typography ,Grid} from "@mui/material";
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web';
const HowItWorks = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
      });
      const springProps = useSpring({
        from: { opacity: 0, transform: 'translateY(50%)' },
        to: async (next) => {
          await next({ opacity: 1, transform: 'translateY(0%)' });
        },
        config: { duration: 500 },
        immediate: !inView,
        reset: true,
      });
    return (
    <animated.div id="HowItWorks" style={{
            ...springProps,
        }} >
    <Box ref={ref} sx={{ color: '#fff', marginTop:5 ,display: 'flex',
        flexDirection: 'column',
        justifyContent:'center',alignItems:'center',}}>
            <Container sx={{ textAlign: 'center', width: '90vw' }} >
                <Typography sx={{ fontFamily: 'Open Sans',
                                fontWeight: 700,fontSize: {xs:34,sm:34,md:54,lg:54,xl:54},}}>How it works?</Typography>
                {/* <Typography sx={{ fontFamily: 'Josefin Sans',marginTop:2,fontSize: {xs:16,sm:16,md:20,lg:20,xl:20},}}>Your Guide to Universal Wisdom.</Typography> */}
            </Container>
            <Box sx={{ width:'96vw',marginTop:5,borderRadius: 4,justifyContent:'center',alignItems:'center',color: '#fff'}}>
                <Grid container sx={{borderRadius: 4,alignItems:'center',justifyContent:'center'}}>
                    <Grid item xs={11} sm={5.5} md={2.9} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <Box sx={{backgroundColor:'#463D7C', borderRadius: '50%', width: 150, height: 150,}}>
                            <Typography sx={{ textAlign:'center',fontFamily: 'Josefin Sans', fontSize: 74,marginTop:3 }}>1</Typography>
                        </Box>
                        <Box sx={{width:250,height:120,marginTop:3}}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, textAlign:'center'}}>
                            Install the app
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={11} sm={5.5} md={2.9} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <Box sx={{backgroundColor:'#463D7C', borderRadius: '50%', width: 150, height: 150,}}>
                            <Typography sx={{ textAlign:'center',fontFamily: 'Josefin Sans', fontSize: 74,marginTop:3 }}>2</Typography>
                        </Box>
                        <Box sx={{width:250,height:120,marginTop:3}}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, textAlign:'center'}}>
                            Explore Skoobs
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={11} sm={5.5} md={2.9} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <Box sx={{backgroundColor:'#463D7C', borderRadius: '50%', width: 150, height: 150,}}>
                            <Typography sx={{ textAlign:'center',fontFamily: 'Josefin Sans', fontSize: 74,marginTop:3 }}>3</Typography>
                        </Box>
                        <Box sx={{width:250,height:120,marginTop:3}}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{ fontFamily: 'Open Sans',
                                fontWeight: 400, textAlign:'center'}}>
                            Begin Reading Or Listening
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={11} sm={5.5} md={2.9} sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <Box sx={{backgroundColor:'#463D7C', borderRadius: '50%', width: 150, height: 150,}}>
                            <Typography sx={{ textAlign:'center',fontFamily: 'Josefin Sans', fontSize: 74,marginTop:3 }}>4</Typography>
                        </Box>
                        <Box sx={{width:250,height:120,marginTop:3}}>
                            <Typography gutterBottom variant="h5" component="div" color="#fff" sx={{fontFamily: 'Open Sans',
                                fontWeight: 400,textAlign:'center'}}>
                            Become a Knowledge Expert
                            </Typography>
                        </Box>
                    </Grid>
            </Grid>
        </Box>
        </Box>
    </animated.div>
    )
}

export default HowItWorks