import React, {useEffect}from  'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import DeepLinkLanding from './pages/DeepLinkLanding';
import UnsubscribeEmails from './pages/Unsubscribe';

function App() {
  const BACKEND_URL = 'https://api.skoob.ai';
  localStorage.setItem('unique_key', "hhlo4pwh:bsiphc60");
  localStorage.setItem('BACKEND_URL', BACKEND_URL);
  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua)){
      return "iOS"
    }
    return "Desktop"
  }
  useEffect(()=>{
    if(getMobileOS() === "Android"){
      localStorage.setItem('url','https://play.google.com/store/apps/details?id=com.skoobai');
    }
    else if(getMobileOS() === "iOS"){
      localStorage.setItem('url','https://apps.apple.com/us/app/skoob-ai/id6473138854');
    }
    else{
      localStorage.setItem('url','https://apps.apple.com/us/app/skoob-ai/id6473138854');
    }
  })
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/unsubscibe/:uid' element={<UnsubscribeEmails />} />
          <Route path="/trivia/:id" element={<DeepLinkLanding/>} />
          <Route path="/trackplayer/:story_id/:skoob_id" element={<DeepLinkLanding/>} />
          <Route path="/readpage/:story_id/:skoob_id" element={<DeepLinkLanding/>} />
          <Route path="/skoobdetails/:id" element={<DeepLinkLanding/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
