import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import FooterImage from "../assets/FooterImage.svg";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import Instagram from "../assets/Instagram.svg";
import Linkedin from "../assets/Linkedin.svg";
import Facebook from "../assets/Facebook.svg";

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          height: {
            xs: "120px",
            sm: "140px",
            md: "160px",
            lg: "200px",
            xl: "220px",
          },
          width: "100%",
          backgroundImage: `url(${FooterImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Box sx={{ marginLeft: "5vw", paddingTop: "1vh" }}>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Open Sans",
              fontSize: { xs: 18, sm: 24, md: 30, lg: 40, xl: 50 },
              fontWeight: "500",
            }}
          >
            Are You Set to Skoob?
          </Typography>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontFamily: "Open Sans",
              fontSize: { xs: 14, sm: 20, md: 26, lg: 30, xl: 40 },
              fontWeight: "300",
            }}
          >
            Let's Dive In!
          </Typography>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#200E4E", paddingBottom: "60px" }}>
        <Box sx={{ marginLeft: "5vw" }}>
          <Typography
            sx={{
              color: "#FFFFFF",
              paddingTop: "24px",
              fontFamily: "Open Sans",
              fontSize: { xs: 12, sm: 16, md: 20, lg: 26, xl: 32 },
              fontWeight: "400",
            }}
          >
            FOLLOW US{" "}
          </Typography>
          <Box sx={{ paddingTop: "10px" }}>
            <a href="https://www.instagram.com/skoob.ai?igsh=Y3AzYm11dHh0Nmx5">
              <img
                src={Instagram}
                alt="Instagram"
                width={"30px"}
                height={"30px"}
                // style={{
                //     width:'30px',
                //     height:'30px',
                // }}
              />
            </a>
            <a href="https://www.linkedin.com/company/skoob-ai/">
              <img
                src={Linkedin}
                alt="Linkedin"
                width={"32px"}
                height={"32px"}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </a>
            <a href="https://www.facebook.com/people/Skoob-AI/pfbid0DSZ6wNKVYtU9T19t4KtpF3CXZsGou1eGJumUy94i6k2YbePLXCPvFPhdavzT7heYl/">
              <img
                src={Facebook}
                alt="Facebook"
                width={"30px"}
                height={"30px"}
              />
            </a>
          </Box>
          <ScrollLink
            to={"Home"}
            spy={true}
            smooth={true}
            duration={500}
            offset={-200}
            style={{ cursor: "pointer" }} 
            >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: { xs: 12, sm: 16, md: 20, lg: 26, xl: 32 },
                fontWeight: "400",
                marginTop: "14px",
              }}
            >
              COMPANY
            </Typography>
          </ScrollLink>
          <ScrollLink
            to={"Features"}
            spy={true}
            smooth={true}
            duration={500}
            offset={-200}
            style={{ cursor: "pointer" }} 
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: { xs: 16, sm: 18, md: 24, lg: 30, xl: 36 },
                fontWeight: "400",
                marginTop: "16px",
              }}
            >
              About
            </Typography>
          </ScrollLink>
          {/* <Link to='AboutMindverse' ><Typography sx={{color:'#FFFFFF',fontFamily:'Open Sans',fontSize:{xs:16,sm:18,md:24,lg:30,xl:36},fontWeight:'400',marginTop:'16px'}}>Contact & help</Typography></Link> */}
          <a style={{ textDecoration: "none" }} href="https://skoob.ai/privacy">
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: { xs: 16, sm: 18, md: 24, lg: 30, xl: 36 },
                fontWeight: "400",
                marginTop: "16px",
              }}
            >
              Privacy Policy
            </Typography>
          </a>
          <a style={{ textDecoration: "none" }} href="https://skoob.ai/terms">
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: { xs: 16, sm: 18, md: 24, lg: 30, xl: 36 },
                fontWeight: "400",
                marginTop: "16px",
              }}
            >
              Terms of use
            </Typography>
          </a>
        </Box>
      </Box>
    </>
  );
};
export default Footer;
